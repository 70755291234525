
import Form from "@/components/Form.vue";
import { defineComponent } from "vue";
import Grid from "@/components/Grid.vue";
import { useStore } from "./store/index";

export default defineComponent({
  name: "App",
  components: {
    Form,
    Grid,
  },
  setup() {
    const store = useStore();
    console.log(store)
    store.state.columnArr.push(
      { unit: "1fr" },
      { unit: "1fr" },
      { unit: "1fr" },
      { unit: "1fr" },
      { unit: "1fr" }
    );
    store.state.rowArr.push(
      { unit: "1fr" },
      { unit: "1fr" },
      { unit: "1fr" },
      { unit: "1fr" },
      { unit: "1fr" }
    );
  },
});
