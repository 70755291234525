
import { defineComponent, toRefs, reactive } from "vue";
import { mapState, mapMutations } from "vuex";
import Modal from "@/components/Modal.vue";
import Code from '@/components/Code.vue';


export default defineComponent({
  setup() {
    const state = reactive({
      showCodeModal: false,
    });
    return {
      ...toRefs(state),
    };
  },
  components: {
    Modal,

    Code
  },
  watch: {
    columns(newv, oldv) {
      const payload = {
        newv,
        oldv,
        direction: "columnArr",
      };
      this.adjustArr(payload);
    },
    rows(newv, oldv) {
      const payload = {
        newv,
        oldv,
        direction: "rowArr",
      };
      this.adjustArr(payload);
    },
  },
  computed: {
    ...mapState(["columns", "rows", "columnGap", "rowGap"]),
  },
  methods: {
    ...mapMutations([
      "updateColumns",
      "updateRows",
      "updateColumnGap",
      "updateRowGap",
      "adjustArr",
    ]),
  },
});
