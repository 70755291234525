
import { defineComponent, reactive } from "vue";
import { mapState, mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default defineComponent({
  setup() {
    const state = reactive({
      place: { downRow: 0, downColumn: 0, upRow: 0, upColumn: 0 },
    });
    return {
      state,
    };
  },
  computed: {
    ...mapGetters(["templateCol", "templateRow", "divCount"]),
    ...mapState([
      "columns",
      "rows",
      "columnGap",
      "rowGap",
      "columnArr",
      "rowArr",
      "childarea",
    ]),
  },
  methods: {
    placeChild(item: number, action: "down" | "up") {
      this.state.place[`${action}Row` as "downRow" | "upRow"] = Math.ceil(
        item / this.columns
      );
      this.state.place[`${action}Column` as "downRow" | "upColumn"] =
        Math.floor(
          item -
            (this.state.place[`${action}Row` as "downRow" | "upRow"] - 1) *
              this.columns
        );

      if (action === "up") {
        let [startRow, endRow] =
          this.state.place.downRow > this.state.place.upRow
            ? [this.state.place.upRow, this.state.place.downRow]
            : [this.state.place.downRow, this.state.place.upRow];
        let [startColumn, endColumn] =
          this.state.place.downColumn > this.state.place.upColumn
            ? [this.state.place.upColumn, this.state.place.downColumn]
            : [this.state.place.downColumn, this.state.place.upColumn];

        console.log({ startRow, endRow, startColumn, endColumn });
        this.addChildarea(
          `${startRow} / ${startColumn} / ${endRow + 1} / ${endColumn + 1}`
        );
      }
    },
    ...mapMutations([
      "addChildarea", //also supports payload `this.nameOfMutation(amount)`
      "removeChildarea",
    ]),
  },
});
